import React from 'react'

/**
 */
export const withPropsFromValue =
  (Component) =>
  ({ value, propsFromValue, ...props }) =>
    (
      <Component
        {...props}
        {...(propsFromValue ? propsFromValue(value) : {})}
        value={value}
      />
    )
