import React from 'react'

import { useList } from 'react-use'

import { Context } from './Context'


/**
 */
export const Initializer = ({
  children,
  entries: givenEntries,
  total = null,
  ...props
}) => {
  const [
    entries,
    { set: setEntries, sort: sortEntries, filter: filterEntries },
  ] = useList([])

  const [defaultEntries, setDefaultEntries] = React.useState([])

  React.useEffect(() => {
    setEntries(givenEntries ?? [])
    setDefaultEntries(givenEntries ?? [])
  }, [givenEntries])

  return (
    <Context.Provider
      value={{
        defaultEntries,
        entries,
        setEntries,
        sortEntries,
        filterEntries,
        resetEntries: React.useCallback(() => {
          setEntries(defaultEntries ?? [])
        }, [defaultEntries]),
        total: total ?? entries?.length,
      }}
      {...props}
    >
      {children}
    </Context.Provider>
  )
}
