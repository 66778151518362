import React from 'react'

import { Context } from './Context'
/**
 */
export const Initializer = ({ children, entry }) => {
  const context = React.useContext(Context)

  if (!context) {
    return <Context.Provider value={{ entry }}>{children}</Context.Provider>
  }

  return <>{children}</>
}
