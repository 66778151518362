import * as BooleanHelper from '@arch-log/webapp.lib/BooleanHelper'

/**
 */
export const createHasPermission =
  (containedPermissions) => (validatePermissions) => {
    return BooleanHelper.arrayOr(validatePermissions, (perms) =>
      BooleanHelper.arrayAnd(perms, (perm) =>
        containedPermissions.includes(perm),
      ),
    )
  }
