import React from 'react'

import * as config from '@arch-log/webapp.modules/config/config.js'


export const useConfigs = () => {
  return config.all()
}

export const useConfig = (key) => {
  return config.get(key)
}
