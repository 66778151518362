import React from 'react'

import { withUseProperty } from '@arch-log/webapp.modules/utils/components/withUseProperty'
import { useProperty } from './useProperty'
import * as Renderers from '@arch-log/webapp.modules/project.board.v2/components/PropertyRenderers'

const withProperty = (selector, Component) => (props) => {
  const value = useProperty(selector)
  return (<Component {...props} value={ value }/>)
}

/**
 */
export const Label = withProperty(data => data.label, Renderers.Label)
export const OwnerLabel = withProperty(data => data.ownerLabel, Renderers.OwnerLabel)
export const CreatedAt = withProperty(data => data.createdAt, Renderers.CreatedAt)
export const UpdatedAt = withProperty(data => data.updatedAt, Renderers.UpdatedAt)
