import React from 'react'

import * as Errors from './Errors'

/**
 */
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    switch(this.state.error?.message) {
      case 'Forbidden':
        return (<Errors.Forbidden { ...this.props }/>)
      default:
        return this.props.children
    }
  }
}
