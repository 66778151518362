import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'
import { withPropsFromValue } from '@arch-log/webapp.modules/utils/PropertyRendererHelper'

/**
 */
export const Label = withPropsFromValue(
  ({
    value,
    defaultValue = null,
    render: Renderer = ValueRenderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value ?? defaultValue} {...props} />
  },
)

export const OwnerLabel = withPropsFromValue(ValueRenderers.TextRenderer)

export const CreatedAt = withPropsFromValue(ValueRenderers.DateTimeRenderer)

/**
 */
export const UpdatedAt = withPropsFromValue(
  ({
    value,
    defaultValue = null,
    render: Renderer = ValueRenderers.DateTimeRenderer,
    ...props
  }) => {
    return <Renderer value={value ?? defaultValue} {...props} />
  },
)
