import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Wrapper = ({ children }) => {
  return (<div className={ styles.Layout_Wrapper }>{ children }</div>)
}

/**
 */
export const SortKey = ({ children }) => {
  return (<div className={ styles.Layout_SortKey }>{ children }</div>)
}

/**
 */
export const Direction = ({ children }) => {
  return (<div className={ styles.Layout_Direction }>{ children }</div>)
}
