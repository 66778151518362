import React from 'react'

import * as MUI from '@mui/material'
import * as Literals from './Literals'

export const Default = ({ onClose, isOpened, ...props }) => (
  <MUI.Dialog open={isOpened} onClose={onClose}>
    <MUI.DialogTitle id="alert-dialog-title">
      <Literals.Title />
    </MUI.DialogTitle>
    <MUI.DialogContent>
      <MUI.DialogContentText id="alert-dialog-description">
        <Literals.Message />
      </MUI.DialogContentText>
    </MUI.DialogContent>
    <MUI.DialogActions>
      <MUI.Button onClick={onClose}>
        <Literals.OK />
      </MUI.Button>
    </MUI.DialogActions>
  </MUI.Dialog>
)
