import { gql } from '@apollo/client'

export const query = gql`
  query ProjectBoards($projectId: UUID!) {
    projectBoards(projectId: $projectId, includeV1: true) {
      total
      entries {
        id
        owner
        ownerLabel
        label
        version
        projectId
        images {
          originalUrl
          thumbnails {
            size
            url
          }
        }
        pdfLink
        editLink
        createdAt
        updatedAt
        permissions
      }
    }
  }
`
