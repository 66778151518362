import React from 'react'

import { useQuery } from '@apollo/client'
import { Context } from './Context'
import { query } from './query'

import { Initializer as ScopedEntries } from '@arch-log/webapp.modules/project.board.v2/components/Scope/Entries/Initializer'

/**
 */
export const Loader = ({ children, projectId, size = 100, offset = 0 }) => {
  const { data, ...more } = useQuery(query, {
    variables: {
      projectId,
      size,
      offset,
    },
  })

  const entries = data?.projectBoards?.entries

  const total = data?.projectBoards?.total

  return (
    <Context.Provider
      value={{
        ...more,
        entries,
        total,
      }}
    >
      <ScopedEntries entries={entries}>{children}</ScopedEntries>
    </Context.Provider>
  )
}
