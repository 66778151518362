import React from 'react'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as RemoteBoard from '@arch-log/webapp.modules/project.board.v2/components/Remote'
import * as Board from '@arch-log/webapp.modules/project.board.v2/components/Scope'
import * as BoardOrder from '@arch-log/webapp.modules/project.board.v2/order'
import * as BaseSorter from '@arch-log/webapp.shared/Sorter'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as Routes from '@arch-log/webapp.shared/Routes'
import * as Literals from './Literals'
import * as Styled from './Styled'
import * as EntryComponents from './Entry'
import * as Sort from './Sort'
import * as styles from './styles.module.scss'
import * as Modals from './Modals'

import { useToggle } from 'react-use'

import { useConfig } from '@arch-log/webapp.modules/config/components/useHooks'

export const Sorter = () => {
  const { setOrderCondition } = React.useContext(Sort.Context)
  const { formatSortKey } = Literals.useLiterals()

  return (
    <BaseSorter.Default
      defaultValue={BoardOrder.OrderBy.UpdatedAt}
      sortKeys={BoardOrder.SortKeys}
      formatSortKey={(v) => formatSortKey(v)}
      onChangeValue={(key, direction) =>
        setOrderCondition({ sortKey: key, direction })
      }
    />
  )
}

/**
 */
export const Initializer = ({ children }) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  return (
    <RemoteBoard.Queries.Entries.Loader projectId={projectEntry.uuid}>
      {children}
    </RemoteBoard.Queries.Entries.Loader>
  )
}

export const Entries = ({
  children,
  components: { Container = Styled.EntriesContainer } = {},
}) => {
  const {
    orderCondition: { sortKey, direction },
  } = React.useContext(Sort.Context)

  const { defaultEntries, sortEntries } = Board.Entries.useEntries()

  React.useEffect(() => {
    sortEntries(BoardOrder.Sorter(sortKey, direction))
  }, [defaultEntries, sortKey, direction])

  return (
    <Container>
      <CreateEntry />
      <Board.Entries.Each>{children}</Board.Entries.Each>
    </Container>
  )
}

export const CreateEntry = ({
  components: { Container = Styled.CreateEntryContainer } = {},
}) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  const { redirectTo } = useNavigate()
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()

  const { total } = RemoteBoard.Queries.Entries.useContext()
  const limit = useConfig('board.limitEntries')

  const [isDialogOpened, toggleDialog] = useToggle(false)

  if (!hasPermission(Permissions.BoardAdd)) {
    return null
  }

  const handleCloseDialog = () => {
    toggleDialog(false)
  }

  const handleCreateEntry = () => {
    console.log(total, limit)
    if (total >= limit) {
      console.log('toggle')
      toggleDialog(true)
    } else {
      redirectTo(Routes.Board.toNew({ project: projectEntry.uuid }))
    }
  }

  return (
    <>
      <Container onClick={handleCreateEntry}>
        <Icons.CreateNew className={styles.CreateEntry_Icon} />
        <Literals.CreateNew />
      </Container>
      <Modals.LimitEntries.Default
        isOpened={isDialogOpened}
        onClose={handleCloseDialog}
      />
    </>
  )
}

export const Entry = ({
  components: { Container = Styled.EntryContainer } = {},
}) => (
  <Container>
    <EntryComponents.Default />
  </Container>
)

export const Title = ({ components: { Decorator = Styled.Title } = {} }) => (
  <Decorator>
    <Literals.Title />
  </Decorator>
)
