import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Direction = ({ children, ...props }) => (
  <div {...props} className={styles.Direction}>
    {children}
  </div>
)
