import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'
import * as Directions from '@arch-log/webapp.lib/order/Direction'

export const Default = ({
  defaultValue,
  onChangeValue,
  sortKeys,
  formatSortKey = v => v,
  directions = [Directions.Asc, Directions.Desc],
  components: {
    Layout: {
      Wrapper = Layout.Wrapper,
      KeySection = Layout.SortKey,
      DirectionSection = Layout.Direction,
    } = {},
  } = {},
}) => {
  const [order, setOrder] = React.useState({
    sortKey: defaultValue ?? sortKeys?.[0],
    direction: Directions.Desc,
  })

  React.useEffect(() => {
    if(onChangeValue) {
      onChangeValue(order.sortKey, order.direction)
    }
  }, [order])

  return (
    <Wrapper>
      <KeySection>
        <Containers.KeySelector
          onChangeValue={(value) => {
            setOrder({ ...order, sortKey: value })
          }}
          value={order.sortKey}
          values={ sortKeys }
          formatSortKey={ formatSortKey }
        />
      </KeySection>
      <DirectionSection>
        <Containers.DirectionSelector
          onChangeValue={(value) => {
            setOrder({ ...order, direction: value })
          }}
          value={order.direction}
        />
      </DirectionSection>
    </Wrapper>
  )
}
