import React from 'react'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'
import * as Steps from './Steps'
import { ErrorBoundary } from './ErrorBoundary'
import * as RemoteBoard from '@arch-log/webapp.modules/project.board.v2/components/Remote'

import * as MUI from '@mui/material'
import { Context } from './Context'

/**
 */
const BaseFlow = createFlow([Steps.Confirm, Steps.Progress, Steps.Refresh, Steps.Complete])

/**
 */
export const Flow = ({ ...props }) => {
  const { stop } = React.useContext(Context)

  const handleClose = () => {
    // ignore
  }

  const handleCancel = () => {
    stop()
  }

  return (
    <MUI.Dialog open={true} onClose={handleClose}>
      <ErrorBoundary close={handleCancel}>
        <RemoteBoard.Mutations.DeleteEntry.Initializer
          onCompleted={() => {
            // go to project page
            console.log('close')
          }}
        >
          <BaseFlow {...props} cancel={handleCancel} />
        </RemoteBoard.Mutations.DeleteEntry.Initializer>
      </ErrorBoundary>
    </MUI.Dialog>
  )
}
