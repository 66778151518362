import React from 'react'
import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'


export const Title = translated('app')('BoardList.LimitEntries.Title')

export const Message = translated('app')('BoardList.LimitEntries.Message')

export const OK = translated(['common', 'app'])(['app:BoardList.LimitEntries.OK', 'common:OK'])

