import React from 'react'

import * as styles from './styles.module.scss'

export const Container = ({ children }) => <>{children}</>

export const ThumbnailContainer = ({ children }) => <>{children}</>

export const InfoContainer = ({ children }) => <>{children}</>

export const DeleteContainer = ({ children }) => (
  <div className={styles.DeleteContainer}>{children}</div>
)

export const Author = ({ children, editHovering }) => (
  <div
    className={editHovering ? styles.Entry_Author_Hovered : styles.Entry_Author}
  >
    {children}
  </div>
)

export const DeleteButton = ({ children, onDelete }) => (
  <div className={styles.DeleteButton} onClick={() => onDelete()}>
    {children}
  </div>
)
