import React, { forwardRef } from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

export const Edit = forwardRef(({ children, ...props }, ref) => (
  <div {...props} className={styles.Edit} ref={ref}>
    {children}
  </div>
))

/**
 */
export const Delete = () => (
  <Icons.Delete className={styles.DeleteButton_Icon} />
)
