import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as Directions from '@arch-log/webapp.lib/order/Direction'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'

export const KeySelector = ({
  value,
  values,
  onChangeValue,
  formatSortKey,
}) => {
  const opts = values.map((v) => (
    <option key={v} value={v}>
      {formatSortKey(v)}
    </option>
  ))

  return (
    <select
      className={styles.SortKey}
      value={value}
      onChange={(e) => {
        if (onChangeValue) {
          onChangeValue(e.target.value)
        }
      }}
    >
      {opts}
    </select>
  )
}

export const DirectionSelector = ({ value, onChangeValue }) => {
  const icon = (() => {
    if (value === Directions.Asc) {
      return <Icons.SortAsc />
    }
    return <Icons.SortDesc />
  })()

  return (
    <Styled.Direction
      onClick={() => {
        const newDirection = Directions.oposite(value)

        onChangeValue(newDirection)
      }}
    >
      {icon}
    </Styled.Direction>
  )
}
