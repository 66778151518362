import { gql } from '@apollo/client'

export const query = gql`
  query ProjectBoard($id: String!) {
    projectBoard(id: $id) {
      id
      label
      version
      canvas
      projectId
      permissions
    }
  }
`
