import React from 'react'

import * as Entry from '@arch-log/webapp.modules/project.board.v2/components/Scope/Entry'

import { Context } from './Context'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const Each = ({
  //
  render: Renderer = ChildrenRenderer,
  ...props
}) => {
  const { entries } = React.useContext(Context)

  return entries.map((entry, index) => {
    return (
      <Entry.Initializer entry={entry} key={index}>
        <Renderer {...props} />
      </Entry.Initializer>
    )
  })
}
