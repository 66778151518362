// extracted by mini-css-extract-plugin
export var DeleteButton = "styles-module--DeleteButton--zbZxY";
export var DeleteButton_Icon = "styles-module--DeleteButton_Icon--qi0zv";
export var DeleteContainer = "styles-module--DeleteContainer--SEnph";
export var Edit = "styles-module--Edit--MMqNb";
export var Entry_Author = "styles-module--Entry_Author--0bQUj";
export var Entry_Author_Hovered = "styles-module--Entry_Author_Hovered--Nn7wt";
export var Entry_Date = "styles-module--Entry_Date--dWKdT";
export var Entry_Date_Hovered = "styles-module--Entry_Date_Hovered--atJLL";
export var Entry_Name = "styles-module--Entry_Name--Ag+ba";
export var Entry_Thumbnail = "styles-module--Entry_Thumbnail--yNieu";