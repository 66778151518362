import React from 'react'

import * as MUI from '@mui/material'

export const Forbidden = ({ close }) => {
  return (
    <>
      <div>権限がありません。</div>
      <div>
        <MUI.Button onClick={close}>OK</MUI.Button>
      </div>
    </>
  )
}
