import React from 'react'

import { useProperty } from './useProperty'
import * as Renderers from '@arch-log/webapp.modules/project.board.v2/components/PropertyRenderers/Image'

const withProperty = (selector, Component) => (props) => {
  const value = useProperty(selector)

  return <Component {...props} value={value} />
}

/**
 */
export const Original = withProperty((data) => {
  return { src: data.originalUrl, thumbnails: data.thumbnails }
}, Renderers.Renderer)

/**
 */
export const Thumbnail = withProperty((data) => {
  return {
    supportedSize: ['256x256', '1024x1024', '2048x2048', 'original'],
    images: [...(data.thumbnails ?? []), { size: 'original', url: data.originalUrl}],
  }
}, Renderers.Thumbnail)
