import {
  comparatorByProperty,
  Direction,
} from '@arch-log/webapp.lib/order'

/**
 */
export const OrderBy = {
  Label: 'label',
  Owner: 'owner',
  UpdatedAt: 'updatedAt',
}

/**
 */
export const SortKeys = [OrderBy.Label, OrderBy.Owner, OrderBy.UpdatedAt]

/**
 * @return SortFunction
 */
export const Sorter = (key = OrderBy.Label, direction = Direction.Desc) => {
  switch (key) {
    case OrderBy.Label:
      return comparatorByProperty((entry) => entry.label, direction)
    case OrderBy.Owner:
      return comparatorByProperty((entry) => entry.ownerLabel, direction)
    case OrderBy.UpdatedAt:
      return comparatorByProperty((entry) => entry.updatedAt, direction)
    default:
      return (a, b) => 0
  }
}

/**
 */
export { Direction }
