import React, { forwardRef } from 'react'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'

import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as BoardEntry from '@arch-log/webapp.modules/project.board.v2/components/Scope/Entry'
import * as BoardRemote from '@arch-log/webapp.modules/project.board.v2/components/Remote'

import * as DefaultComponents from './DefaultComponents'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'
import * as Actions from './Actions'
import { useHoverDirty } from 'react-use'

export const Thumbnail = ({
  components: { Container = DefaultComponents.ThumbnailContainer } = {},
}) => (
  <Container>
    <BoardEntry.Properties.Images.Thumbnail
      size="256x256"
      className={styles.Entry_Thumbnail}
      style={{
        maxWidth: 200,
        maxHeight: 200,
        objectFie: 'contain',
      }}
    />
  </Container>
)

const Edit = forwardRef(
  ({ children, components: { Decorator = Styled.Edit } = {} }, ref) => {
    const { entry } = BoardEntry.useEntry()

    const { redirectTo } = useNavigate()
    return (
      <Decorator
        onClick={() => {
          //window.open(
          //  Routes.Board.toEdit({ project: board?.project_id, id: board?.id }),
          //)
          window.location = entry.editLink
        }}
        ref={ref}
      >
        {children}
      </Decorator>
    )
  },
)

const Delete = ({
  components: {
    Container = DefaultComponents.DeleteContainer,
    Button = DefaultComponents.DeleteButton,
    Delete = Styled.Delete,
  } = {},
}) => {
  const { start: startDeleteEntry } = Actions.DeleteEntry.useFlow()

  return (
    <Container>
      <Button
        onDelete={() => {
          startDeleteEntry()
        }}
      >
        <Delete />
      </Button>
    </Container>
  )
}

const Trush = () => {
  const { entry } = BoardEntry.useEntry()

  const [_, { hasPermission }] = BoardRemote.Queries.Entries.usePermissions(
    entry,
  )

  if (!hasPermission(Permissions.BoardRemove)) {
    return null
  }

  return <Delete />
}

export const Author = ({
  components: { Decorator = DefaultComponents.Author } = {},
  editHovering,
}) => (
  <Decorator editHovering={editHovering}>
    <BoardEntry.Properties.OwnerLabel />
  </Decorator>
)

export const Info = ({
  components: { Container = DefaultComponents.InfoContainer } = {},
  editHovering,
}) => {
  return (
    <Container>
      <BoardEntry.Properties.Label className={styles.Entry_Name} />
      <Author editHovering={editHovering} />
      <BoardEntry.Properties.UpdatedAt
        className={editHovering ? styles.Entry_Date_Hovered : styles.Entry_Date}
      />
      <Trush />
    </Container>
  )
}
export const Default = ({
  components: { Container = DefaultComponents.Container } = {},
}) => {
  const ref = React.useRef(null)

  const isHovering = useHoverDirty(ref)

  return (
    <Actions.DeleteEntry.Initializer>
      <Container>
        <Edit ref={ref}>
          <Thumbnail />
        </Edit>
        <Info editHovering={isHovering} />
      </Container>
    </Actions.DeleteEntry.Initializer>
  )
}
