import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

export const Cancel = translated('common')('Cancel')

export const OK = translated('common')('Yes')
    
export const Message = translated('app.project')('BoardList.Delete.Message')
