import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const EntriesContainer = ({ children }) => (
  <div className={styles.EntriesContainer}>{children}</div>
)

/**
 */
export const CreateEntryContainer = ({ children, ...props }) => (
  <div className={styles.EntryContainer} {...props}>
    <div className={styles.CreateEntry}>{children}</div>
  </div>
)

/**
 */
export const EntryContainer = ({ children, ...props }, ref) => (
  <div className={styles.EntryContainer} {...props}>
    {children}
  </div>
)

/**
 */
export const Title = ({ children }) => (
  <span className={styles.Title}> {children}</span>
)
