import React from 'react'

import { Context } from './Context'
import * as BoardOrder from '@arch-log/webapp.modules/project.board.v2/order'

export const Initializer = ({ children }) => {
  const [orderCondition, setOrderCondition] = React.useState({
    sortKey: BoardOrder.OrderBy.UpdatedAt,
    direction: BoardOrder.Direction.Desc,
  })

  return (
    <Context.Provider value={{ orderCondition, setOrderCondition }}>
      {children}
    </Context.Provider>
  )
}
