import React from 'react'

import { Context } from './Context'
import { query } from './query.js'
import { useMutation } from '@apollo/client'

/**
 */
export const Initializer = ({ children, onCompleted, id, projectId }) => {
  const [deleteEntry, { data, ...res }] = useMutation(query, {
    onCompleted,
    variables: { id, projectId },
  })

  return (
    <Context.Provider
      value={{
        deleteEntry,
        ...res,
        data,
        response: React.useMemo(() => {
          return data?.deleteEntry
        }, [data]),
      }}
    >
      {children}
    </Context.Provider>
  )
}
