import React from 'react'

import { Context } from './Context'
import { Flow } from './Flow'

import { useSetState } from 'react-use'
import * as BoardEntry from '@arch-log/webapp.modules/project.board.v2/components/Scope/Entry'
import * as RemoteBoard from '@arch-log/webapp.modules/project.board.v2/components/Remote'

/**
 */
export const Initializer = ({ ...props }) => {
  const { entry } = BoardEntry.useEntry()

  return (
    <RemoteBoard.Mutations.DeleteEntry.Initializer id={entry.id}>
      <StateInitializer {...props} />
    </RemoteBoard.Mutations.DeleteEntry.Initializer>
  )
}

const StateInitializer = ({
  children,
  start: defaultStarted = false,
  ...props
}) => {
  const [state, setState] = useSetState({
    isStarted: defaultStarted,
    isCompleted: false,
  })

  const start = () => {
    setState({
      isStarted: true,
      isCompleted: false,
    })
  }

  const stop = () => {
    setState({
      isStarted: false,
      isCompleted: false,
    })
  }

  const complete = () => {
    setState({
      isCompleted: true,
    })
  }

  return (
    <Context.Provider value={{ start, stop, complete, ...state }}>
      {children}
      <FlowContent {...props} />
    </Context.Provider>
  )
}

const FlowContent = (props) => {
  const { isStarted, isCompleted, complete } = React.useContext(Context)

  const handleComplete = () => {
    complete()
  }

  if (!isStarted || isCompleted) {
    return null
  }

  return <Flow {...props} onComplete={handleComplete} />
}
