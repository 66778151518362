import { gql } from '@apollo/client'

export const mutation = gql`
  mutation GenerateProjectBoardPdf(
    $id: ID!
  ) {
    generatePdf: generateProjectBoardPdf(id: $id) {
      url
    }
  }
`
