import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'
import * as Sort from './Sort'

/**
 */
export const Default = ({
  components: {
    Wrapper = Layout.Wrapper,
    Header = Layout.Header,
    Body = Layout.Body,
    BodyHeader = Layout.BodyHeader,
    BodyHeaderLeft = Layout.BodyHeaderLeft,
    BodyHeaderRight = Layout.BodyHeaderRight,
    Footer = Layout.Footer,
  } = {},
}) => (
  <Sort.Initializer>
    <Containers.Initializer>
      <Wrapper>
        <Header>
          <Containers.Title />
        </Header>
        <Body>
          <BodyHeader>
            <BodyHeaderLeft>
              <Containers.Sorter />
            </BodyHeaderLeft>
          </BodyHeader>
          <Containers.Entries>
            <Containers.Entry />
          </Containers.Entries>
        </Body>
      </Wrapper>
    </Containers.Initializer>
  </Sort.Initializer>
)
