import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const CreateNew = translated('app.project')('BoardList.CreateNew')

/**
 */
export const Title = translated('app.project')('BoardList.Title')

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('app.project')

  const formatSortKey = (key, ...options) =>
    t(`BoardList.SortKeys.${key}`, ...options)

  return { formatSortKey }
}
