import React from 'react'

import { withPropsFromValue } from '@arch-log/webapp.modules/utils/PropertyRendererHelper'

/**
 */
export const Renderer = withPropsFromValue(
  ({ value = {}, css, style, width, height }) => {
    const { src, alt = '' } = value ?? {}

    const formattedSrc =
      src ?? 'https://image.p5iznjum.arch-log.com/noimage.png'

    return (
      <img
        src={formattedSrc}
        alt={alt}
        style={style}
        width={width}
        height={height}
      />
    )
  },
)

/**
 */
export const Thumbnail = withPropsFromValue(
  ({
    size: preferSize,
    alt,
    value: { images = [], supportedSize = [] },
    ...props
  }) => {
    const selectedSize = [preferSize, supportedSize]
      .flat()
      .find((s) => (images ?? []).flat().find((i) => i.size === s))

    const src = (images ?? []).find((v) => v.size === selectedSize)?.url

    return <Renderer value={{ src, alt }} {...props} />
  },
)
