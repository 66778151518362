import React from 'react'

import * as MUI from '@mui/material'
import * as Icons from '@mui/icons-material'
import { css } from '@emotion/react'
import { useFlow } from '@arch-log/webapp.atomics/components/Flow'
import { Page as Loading } from '@arch-log/webapp.atomics/components/Loader'
import * as RemoteBoard from '@arch-log/webapp.modules/project.board.v2/components/Remote'
import * as BoardEntry from '@arch-log/webapp.modules/project.board.v2/components/Scope/Entry'
import { useAsync } from 'react-use'
import * as Literals from './Literals'

/**
 */
export const Confirm = ({ next, cancel }) => {
  const handleCancel = () => {
    cancel()
  }
  const handleOk = () => {
    next()
  }

  return (
    <>
      <MUI.DialogContent>
        <MUI.DialogContentText>
          <Literals.Message />
        </MUI.DialogContentText>
      </MUI.DialogContent>
      <MUI.DialogActions>
        <MUI.Button autoFocus onClick={handleCancel}>
          <Literals.Cancel />
        </MUI.Button>
        <MUI.Button onClick={handleOk}>
          <Literals.OK />
        </MUI.Button>
      </MUI.DialogActions>
    </>
  )
}

export const Progress = ({ next }) => {
  const { entry } = BoardEntry.useEntry()
  const { deleteEntry } = RemoteBoard.Mutations.DeleteEntry.useMutation()

  useAsync(async () => {
    await deleteEntry({
      variables: { id: entry.id, options: { projectId: entry.projectId } },
    })

    next()
  }, [])

  return (
    <Loading
      css={css`
        min-width: min(80vw, 600px);
      `}
    />
  )
}

export const Refresh = ({ next }) => {
  const { refetch } = RemoteBoard.Queries.Entries.useEntries()

  useAsync(async () => {
    await refetch()

    next()
  }, [])

  return (
    <Loading
      css={css`
        min-width: min(80vw, 600px);
      `}
    />
  )
}

/**
 */
export const Complete = ({ next }) => {
  React.useEffect(() => {
    next()
  }, [])

  return null
}
