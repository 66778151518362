import React from 'react'

import { Context } from './Context'

import * as AuthHelper from '@arch-log/webapp.modules/utils/AuthHelper'

/**
 */
export const useContext = () => {
  return React.useContext(Context)
}

/**
 */
export const useEntries = useContext

/**
 */
export const usePermissions = (entry) => {
  const perms = entry.permissions

  return [perms, { hasPermission: AuthHelper.createHasPermission(perms) }]
}
