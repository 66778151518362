import { gql } from '@apollo/client'

export const query = gql`
  mutation CreateProjectBoard(
    $projectId: UUID!
    $data: CreateProjectBoardInput!
  ) {
    createEntry: createProjectBoard(projectId: $projectId, data: $data) {
      id
    }
  }
`
