// extracted by mini-css-extract-plugin
export var Container = "styles-module--Container--2VCYw";
export var CreateEntry = "styles-module--CreateEntry--fKBKV";
export var CreateEntry_Icon = "styles-module--CreateEntry_Icon--L5B71";
export var EntriesContainer = "styles-module--EntriesContainer--e9muS";
export var EntryContainer = "styles-module--EntryContainer--2X+U6";
export var HeaderContainer = "styles-module--HeaderContainer--nGur-";
export var Layout_BodyHeader = "styles-module--Layout_BodyHeader--FbDLQ";
export var Layout_BodyHeaderLeft = "styles-module--Layout_BodyHeaderLeft--Is29h";
export var Layout_BodyHeaderRight = "styles-module--Layout_BodyHeaderRight--bBeGH";
export var Title = "styles-module--Title--wNobp";